import React from 'react'
import PageTitle from '../../components/PageTitle';
import {
	Row,
	Card,
	CardBody,
	Col,
	Input,
	Button,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap'
import classNames from 'classnames';
import getStatusBadgeClass from '../../functions/getStatusBadgeClass'
import paginationFactory, { SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import PageOptions from '../../components/PageOptions';
import { withTranslation } from 'react-i18next'
import DataSpinner from '../../components/DataLoader';
import ProfitabilityService from '../../services/ProfitabilityService';
import sizePerPageItens from '../../constants/sizePerPageItens';
import BootstrapTable from 'react-bootstrap-table-next';
import RenderingData from '../../constants/RenderingData';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import formatDate from '../../functions/formatDate';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Download from '../../assets/svg/DownloadIcon'


class ProductDetailsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false,
			product: {
				name: undefined,
				business_unit: undefined,
				capacity: undefined,
				form_factor: undefined,
				marketing_name: undefined,
				stmodel: undefined,
				primary_segment: undefined,
			},
			history: []
		};

		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState(prevState => ({
			modal: !prevState.modal,
		}));
	}

	openModalWithClass = className => {
		this.fetchProductDetails(this.props)
		this.setState({ position: className + ' modal-lg', size: null });
		this.toggle();
	};

	fetchProductDetails({ row }) {
		const response = ProfitabilityService.getProductDetails(row.stmodel)
		response.then(res => {

			this.setState({ product: res.data.product, history: res.data.history_price.history })
			console.log(res)
		})
	}

	renderPriceHistory() {
		return this.state.history?.map((item, index) => {
			return (
				<p key={`history-${index}`} className='mb-1'>
					<span>Data: {formatDate(item.created_at)} - </span>
					<span>Preço: {item.price.toLocaleString('en-US', {
						style: 'currency',
						currency: 'USD',
					})}</span>
				</p>
			)
		})
	}

	render() {
		return (
			<span>
				<React.Fragment>
					<span
						onClick={() => this.openModalWithClass('modal-dialog-centered')} className="pointer success-color">
						{this.props.row.name}
					</span>
					<Modal isOpen={this.state.modal} className={this.state.position} toggle={this.toggle}>
						<ModalHeader className="bg-primary revendas-modal" toggle={this.toggle}>
							<h3>
								{this.state.product.name || <Skeleton width={300} />}
							</h3>
						</ModalHeader>
						<ModalBody>
							<Row className='pb-3'>
								<Col>
									<span className="color-success">
										<strong>
											Business Unit:
										</strong>
										<br />
									</span>
									{this.state.product.business_unit || <Skeleton width={100} />}
								</Col>

								<Col>
									<span className="color-success">
										<strong>
											Capacity:
										</strong>
										<br />
									</span>
									{this.state.product.capacity || <Skeleton width={100} />}
								</Col>

								<Col>
									<span className="color-success">
										<strong>
											Form Factor:
										</strong>
										<br />
									</span>
									{this.state.product.form_factor || <Skeleton width={100} />}
								</Col>
							</Row>

							<Row className='pb-3'>
								<Col>
									<span className="color-success">
										<strong>
											Marketing Name:
										</strong>
										<br />
									</span>
									{this.state.product.marketing_name || <Skeleton width={100} />}
								</Col>

								<Col>
									<span className="color-success">
										<strong>
											STModel:
										</strong>
										<br />
									</span>
									{this.state.product.stmodel || <Skeleton width={100} />}
								</Col>
							</Row>
							<hr />

							<Row>
								<Col>
									<span className="color-success">
										<strong>
											Histórico de preços:
										</strong>
									</span>
									<br />
									<div>
										{this.renderPriceHistory()}
									</div>
								</Col>
							</Row>
							<ModalFooter>
								<Button className='bg-primary ' onClick={this.toggle}>
									<span>
										Fechar
									</span>
								</Button>{' '}
							</ModalFooter>
						</ModalBody>
					</Modal>
				</React.Fragment>
			</span >
		);
	}
}
const FileIcon = ({ hasFile }) => <i className={`mdi ${hasFile !== undefined ? 'mdi-file' : 'mdi-file-outline'} mr-1`}></i>
const LabelBtn = ({ children }) => <label className='mr-2 btn btn-secondary mb-0'>{children}</label>
const TableWithSearch = ({ data, columns, fetchedData, onTableChange, filterOptions }) => {
	const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
		<React.Fragment>
			<label className="d-inline mr-1">Mostrar</label>
			<UncontrolledDropdown className="d-inline">
				<DropdownToggle caret tag="button" type="button" className="btn btn-outline-secondary btn-sm">
					{currSizePerPage}
				</DropdownToggle>
				<DropdownMenu>
					{options.map((option, idx) => (
						<DropdownItem
							key={idx}
							type="button"
							className={classNames({ active: currSizePerPage + '' === option.page + '' })}
							onClick={() => onSizePerPageChange(option.text)}>
							{option.text}
						</DropdownItem>
					))}
				</DropdownMenu>
			</UncontrolledDropdown>
			<label className="d-inline ml-1">Revendas</label>
		</React.Fragment>
	);
	const paginationOptions = {
		sizePerPage: filterOptions.sizePerPage,
		page: filterOptions.pageIndex,
		totalSize: filterOptions.totalSize,
		sizePerPageList: sizePerPageItens,
		showTotal: true,
		onSizePerPageChange: (data) => onTableChange('pagination', { sizePerPage: data }),
		paginationTotalRenderer: (from, to, size) => (
			<span>Mostrando {from}-{to} de {size}</span>
		),
		sizePerPageRenderer: sizePerPageRenderer,
		lastPageTitle: "Última página",
		nextPageTitle: "Próxima página",
		prePageTitle: "Página anterior",
		firstPageTitle: "Primeira página"
	}
	const { SearchBar } = Search;

	return (
		<React.Fragment>
			<ToolkitProvider
				bootstrap4
				keyField="id"
				data={data}
				columns={columns}
				search
			>
				{props => (
					<React.Fragment>
						<Row>
							<Col>
								<SizePerPageDropdownStandalone {...paginationOptions} />
							</Col>

							{/* <Col id="search-bar" className="text-right">
								<SearchBar placeholder="Digite pelo menos 3 caracteres" {...props.searchProps} />
							</Col> */}
						</Row>
						<BootstrapTable
							{...props.baseProps}
							remote
							noDataIndication={() => !fetchedData && <RenderingData />}
							striped
							onTableChange={onTableChange}
							bordered={false}
							pagination={paginationFactory({ ...paginationOptions, hideSizePerPage: true })}
							wrapperClasses="table-responsive"
						/>
					</React.Fragment>
				)}
			</ToolkitProvider>
		</React.Fragment>
	);
};

class Profitability extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: false,
			filterOptions: {
				country: undefined,
				id: undefined,
				date: undefined
			},
			productsTableController: {
				search: undefined,
				sortField: undefined,
				sortOrder: undefined,
				sizePerPage: sizePerPageItens[0].value,
				pageIndex: 1,
				totalSize: undefined,
			},
			headerFixed: false,
			productsList: undefined,
			productsFile: undefined,
			teste: undefined
		}
		this.tglLoading = this.tglLoading.bind(this)
		this.resetProdcuts = this.resetProdcuts.bind(this)
		this.fileChange = this.fileChange.bind(this)

		this.onTableChange = this.onTableChange.bind(this)
		this.selectFilter = this.selectFilter.bind(this)
		this.requestNewValues = this.requestNewValues.bind(this)
	}

	async componentDidMount() {
		this.fetchProducts({ ...this.state.productsTableController, ...this.state.filterOptions })
	}

	tglLoading() {
		this.setState({ isLoading: !this.state.isLoading })
	}

	fileChange(e) {
		this.setState({ [e.target.name]: e.target.files[0] })
	}

	async submitFiles() {
		this.tglLoading()

		const fdProducts = new FormData();

		fdProducts.append('file', this.state.productsFile);

		await Promise.all([
			this.state.productsFile && ProfitabilityService.importResellers(fdProducts),
		])

		this.fetchProducts({ ...this.state.productsTableController, ...this.state.filterOptions })
		this.setState({ productsFile: undefined })

		this.tglLoading()
	}

	clampCell(cell, row) {
		return (
			<span className='clamp-line' title={cell}>
				{cell}
			</span>
		)
	}

	priceCell(cell, row) {
		const formatedValue = cell?.toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD',
		})
		return (
			<span className='clamp-line' title={formatedValue}>{formatedValue}</span>
		)
	}

	dateCell(cell, row, rowIndex, extraData) {
		const formatedValue = formatDate(cell)
		return (
			<span className='clamp-line' title={formatedValue}>{formatedValue}</span>
		)
	}

	statusCell(cell, row, rowIndex, extraData) {
		return (
			<span
				className='clamp-line'
				title={cell}>
				<span className={classNames('badge', getStatusBadgeClass(cell))}>
					{cell}
				</span>
			</span>
		)
	}

	downloadCell(cell, row, rowIndex, extraData) {
		return (
			<a href={cell} download>
				<span>
					<Download customClass="ml-4 " />
				</span>
			</a>
		)
	}

	validationCell(cell, row, rowIndex, extraData) {
		return (
			<span
				className='clamp-line'
				title={cell}>
				{cell}
			</span>
		)
	}

	columns = [
		{
			dataField: 'status',
			text: 'Status',
			sort: true,
			formatter: this.statusCell,
		},
		{
			dataField: 'invalid',
			text: 'Inválido',
			sort: true,
			formatter: this.validationCell,
		},
		{
			dataField: 'records',
			text: 'Registro',
			sort: true,
			formatter: this.validationCell,
		},
		{
			dataField: 'valid',
			text: 'Válido',
			sort: true,
			formatter: this.validationCell,
		},
		{
			dataField: 'file',
			text: 'Arquivo',
			formatter: this.downloadCell,
		},
		{
			dataField: 'created_at',
			text: 'Data de cadastro',
			sort: true,
			formatter: this.dateCell,
		},
	];

	fetchProducts(filterOptions) {
		if (this.state.productsList)
			this.resetProdcuts()
		this.tglLoading()
		const response = ProfitabilityService.listResellers()
		response.then(res => {
			let { productsTableController } = this.state

			productsTableController.pageIndex = res.data.current_page
			productsTableController.sizePerPage = res.data.per_page
			productsTableController.totalSize = res.data.total

			this.setState({
				productsList: res.data.data,
				productsTableController
			})
			this.tglLoading()
		})
	}

	async selectFilter(e, flag) {
		const value = e.target.value
		let filterOptions = this.state.filterOptions

		filterOptions[flag] = value

		if (flag === 'country')
			filterOptions['id'] = undefined

		this.setState(filterOptions)
	}

	resetProdcuts() {
		this.setState({
			productsList: undefined
		})
	}

	requestNewValues() {
		this.fetchProducts({ ...this.state.productsTableController, ...this.state.filterOptions })
	}

	async onTableChange(type, newState) {
		let currentFilterOptions = this.state.productsTableController

		if (type === 'search') {
			if (newState.searchText.length > 2 || newState.searchText.length === 0) {
				this.resetProdcuts()
				currentFilterOptions.search = newState.searchText
				this.fetchProducts({ ...currentFilterOptions, pageIndex: newState.page, ...this.state.filterOptions })
			}
		}
		else {
			this.resetProdcuts()
			if (type === 'sort') {
				if (newState.sortField === 'mainSegment')
					newState.sortField = 'segments'
				const realField = newState.sortField.includes('.') ? newState.sortField.substr(newState.sortField.indexOf('.') + 1) : newState.sortField
				currentFilterOptions.sortField = realField
				currentFilterOptions.sortOrder = newState.sortOrder
			}
			else if (type === 'pagination')
				currentFilterOptions.sizePerPage = newState.sizePerPage

			this.fetchProducts({ ...currentFilterOptions, pageIndex: newState.page, ...this.state.filterOptions })
		}
	}

	render() {
		return (
			<>
				<DataSpinner render={this.state.isLoading} />

				<Row>
					<Col>
						<div className={
							`page-title-box ${this.headerFixed ?
								'fixed-page-header'
								:
								''}`
						}>
							<PageTitle
								colClass="p-0"
								customClass="nav-to-right"
								breadCrumbItems={[
									{ label: 'Rentabilidade', path: '/profitability/products', active: false },
									{ label: 'Uploads', path: '/profitability/products', active: true }
								]}
								title='Uploads'
							/>
						</div>
						{this.headerFixed &&
							<div className="fixed-page-header-ghost"></div>
						}
					</Col>
				</Row>

				<Row>
					<Col>
						<div className='mb-1'>
							<LabelBtn>
								<FileIcon hasFile={this.state.productsFile} />
								{this.state.productsFile?.name ? this.state.productsFile?.name : 'Importar revendas'}
								<Input onChange={this.fileChange} id="productsFile" name="productsFile" className='d-none' type='file' />
							</LabelBtn>

							<Button
								onClick={() => this.submitFiles()}
								disabled={!this.state.productsFile}
							>
								Enviar arquivos
							</Button>
						</div>
					</Col>
				</Row>

				<Row>
					<Col>
						<Card className=''>
							<CardBody>
								<TableWithSearch
									data={this.state.productsList || []}
									columns={this.columns}
									fetchedData={this.state.productsList}
									onTableChange={this.onTableChange}
									filterOptions={this.state.productsTableController}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</>
		)
	}
}

export default withTranslation()(Profitability)