function isOnProduction() {
	const isLocalHost = window.location.href.includes('localhost')
	const isHomolog = window.location.href.includes('homolog')
	const isIP = window.location.href.includes('192.')

	return !isLocalHost && !isHomolog && !isIP
}

export default isOnProduction = isOnProduction()

function currentUrl() {
	const isLocalHost = window.location.href.includes('localhost')
	const isHomolog = window.location.href.includes('homolog')
	const isDevelop = window.location.href.includes('develop')
	const isIP = window.location.href.includes('192.')
	const isVite = window.location.href.includes('127.')

	if (isHomolog)
		return 'https://api-homolog.seagatecentral.com.br'
	else if (isDevelop || isIP || isVite || isLocalHost)
		return 'https://api-develop.seagatecentral.com.br'
	else
		return 'https://api.seagatecentral.com.br'

}

export const apiUrlChecked = currentUrl()